export default {
  v: '5.8.1',
  fr: 24,
  ip: 0,
  op: 72,
  w: 1000,
  h: 1000,
  nm: '获奖动画',
  ddd: 0,
  assets: [
    {
      id: 'image_0',
      w: 1000,
      h: 1000,
      u: '/assets/lottie/luckyspin/',
      p: 'star1.png',
      e: 0,
    },
    {
      id: 'image_1',
      w: 1000,
      h: 1000,
      u: '/assets/lottie/luckyspin/',
      p: 'star2.png',
      e: 0,
    },
    {
      id: 'image_2',
      w: 1000,
      h: 1000,
      u: '/assets/lottie/luckyspin/',
      p: 'star3.png',
      e: 0,
    },
    {
      id: 'image_3',
      w: 1000,
      h: 1000,
      u: '/assets/lottie/luckyspin/',
      p: 'star4.png',
      e: 0,
    },
    {
      id: 'image_4',
      w: 1000,
      h: 1000,
      u: '/assets/lottie/luckyspin/',
      p: 'bg.png',
      e: 0,
    },
    {
      id: 'comp_0',
      nm: '星星',
      fr: 24,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: '星星四',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [275.5, 236.375, 0], ix: 2, l: 2},
            a: {a: 0, k: [275.5, 236.375, 0], ix: 1, l: 2},
            s: {
              a: 1,
              k: [
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 0,
                  s: [100, 100, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 12,
                  s: [80, 80, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 24,
                  s: [100, 100, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 36,
                  s: [80, 80, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 48,
                  s: [100, 100, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 60,
                  s: [80, 80, 100],
                },
                {t: 72, s: [100, 100, 100]},
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: '星星三',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [103.875, 435.375, 0], ix: 2, l: 2},
            a: {a: 0, k: [111.875, 433.375, 0], ix: 1, l: 2},
            s: {
              a: 1,
              k: [
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 0,
                  s: [100, 100, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 12,
                  s: [70, 70, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 24,
                  s: [100, 100, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 36,
                  s: [70, 70, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 48,
                  s: [100, 100, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 60,
                  s: [70, 70, 100],
                },
                {t: 72, s: [100, 100, 100]},
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: '星星二',
          refId: 'image_2',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [741.375, 180.5, 0], ix: 2, l: 2},
            a: {a: 0, k: [741.375, 180.5, 0], ix: 1, l: 2},
            s: {
              a: 1,
              k: [
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 0,
                  s: [100, 100, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 12,
                  s: [140, 140, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 24,
                  s: [100, 100, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 36,
                  s: [140, 140, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 48,
                  s: [100, 100, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 60,
                  s: [140, 140, 100],
                },
                {t: 72, s: [100, 100, 100]},
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: '星星一',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [752, 746, 0], ix: 2, l: 2},
            a: {a: 0, k: [752, 746, 0], ix: 1, l: 2},
            s: {
              a: 1,
              k: [
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 0,
                  s: [100, 100, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 12,
                  s: [60, 60, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 24,
                  s: [100, 100, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 36,
                  s: [60, 60, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 48,
                  s: [100, 100, 100],
                },
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 60,
                  s: [60, 60, 100],
                },
                {t: 72, s: [100, 100, 100]},
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      nm: '光圈',
      fr: 24,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: '形状图层 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {x: [0.833], y: [0.833]},
                  o: {x: [0.167], y: [0.167]},
                  t: 0,
                  s: [0],
                },
                {
                  i: {x: [0.833], y: [0.833]},
                  o: {x: [0.167], y: [0.167]},
                  t: 24,
                  s: [100],
                },
                {
                  i: {x: [0.833], y: [0.833]},
                  o: {x: [0.167], y: [0.167]},
                  t: 44,
                  s: [100],
                },
                {t: 56, s: [0]},
              ],
              ix: 11,
            },
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [500, 500, 0], ix: 2, l: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1, l: 2},
            s: {
              a: 1,
              k: [
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 40,
                  s: [12.411, 12.411, 100],
                },
                {t: 56, s: [113.411, 113.411, 100]},
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ef: [
            {
              ty: 29,
              nm: '高斯模糊',
              np: 5,
              mn: 'ADBE Gaussian Blur 2',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: '模糊度',
                  mn: 'ADBE Gaussian Blur 2-0001',
                  ix: 1,
                  v: {
                    a: 1,
                    k: [
                      {
                        i: {x: [0.833], y: [0.833]},
                        o: {x: [0.167], y: [0.167]},
                        t: 40,
                        s: [16],
                      },
                      {t: 56, s: [260]},
                    ],
                    ix: 1,
                  },
                },
                {
                  ty: 7,
                  nm: '模糊方向',
                  mn: 'ADBE Gaussian Blur 2-0002',
                  ix: 2,
                  v: {a: 0, k: 1, ix: 2},
                },
                {
                  ty: 7,
                  nm: '重复边缘像素',
                  mn: 'ADBE Gaussian Blur 2-0003',
                  ix: 3,
                  v: {a: 0, k: 0, ix: 3},
                },
              ],
            },
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {a: 0, k: [234.885, 234.885], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  nm: '椭圆路径 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156862745, 1, 0.666666666667, 1],
                    ix: 3,
                  },
                  o: {a: 0, k: 100, ix: 4},
                  w: {a: 0, k: 14, ix: 5},
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: '描边 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [1.113, -1.71], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [374.887, 374.887], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: '变换',
                },
              ],
              nm: '椭圆 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: '形状图层 1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {x: [0.833], y: [0.833]},
                  o: {x: [0.167], y: [0.167]},
                  t: 0,
                  s: [0],
                },
                {
                  i: {x: [0.833], y: [0.833]},
                  o: {x: [0.167], y: [0.167]},
                  t: 4,
                  s: [0],
                },
                {
                  i: {x: [0.833], y: [0.833]},
                  o: {x: [0.167], y: [0.167]},
                  t: 5,
                  s: [100],
                },
                {
                  i: {x: [0.833], y: [0.833]},
                  o: {x: [0.167], y: [0.167]},
                  t: 12,
                  s: [100],
                },
                {t: 24, s: [0]},
              ],
              ix: 11,
            },
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [500, 500, 0], ix: 2, l: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1, l: 2},
            s: {
              a: 1,
              k: [
                {
                  i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
                  o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
                  t: 8,
                  s: [12.411, 12.411, 100],
                },
                {t: 24, s: [113.411, 113.411, 100]},
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ef: [
            {
              ty: 29,
              nm: '高斯模糊',
              np: 5,
              mn: 'ADBE Gaussian Blur 2',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: '模糊度',
                  mn: 'ADBE Gaussian Blur 2-0001',
                  ix: 1,
                  v: {
                    a: 1,
                    k: [
                      {
                        i: {x: [0.833], y: [0.833]},
                        o: {x: [0.167], y: [0.167]},
                        t: 8,
                        s: [16],
                      },
                      {t: 24, s: [260]},
                    ],
                    ix: 1,
                  },
                },
                {
                  ty: 7,
                  nm: '模糊方向',
                  mn: 'ADBE Gaussian Blur 2-0002',
                  ix: 2,
                  v: {a: 0, k: 1, ix: 2},
                },
                {
                  ty: 7,
                  nm: '重复边缘像素',
                  mn: 'ADBE Gaussian Blur 2-0003',
                  ix: 3,
                  v: {a: 0, k: 0, ix: 3},
                },
              ],
            },
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {a: 0, k: [234.885, 234.885], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  nm: '椭圆路径 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.992156862745, 1, 0.666666666667, 1],
                    ix: 3,
                  },
                  o: {a: 0, k: 100, ix: 4},
                  w: {a: 0, k: 14, ix: 5},
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: '描边 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [1.113, -1.71], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [374.887, 374.887], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: '变换',
                },
              ],
              nm: '椭圆 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      nm: '气泡',
      fr: 24,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: '气泡 8',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [138.018, 931.737, 0], ix: 2, l: 2},
            a: {a: 0, k: [83.018, 342.23, 0], ix: 1, l: 2},
            s: {a: 0, k: [85.095, 85.095, 100], ix: 6, l: 2},
          },
          ao: 0,
          ef: [
            {
              ty: 29,
              nm: '高斯模糊',
              np: 5,
              mn: 'ADBE Gaussian Blur 2',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: '模糊度',
                  mn: 'ADBE Gaussian Blur 2-0001',
                  ix: 1,
                  v: {a: 0, k: 18.4, ix: 1},
                },
                {
                  ty: 7,
                  nm: '模糊方向',
                  mn: 'ADBE Gaussian Blur 2-0002',
                  ix: 2,
                  v: {a: 0, k: 1, ix: 2},
                },
                {
                  ty: 7,
                  nm: '重复边缘像素',
                  mn: 'ADBE Gaussian Blur 2-0003',
                  ix: 3,
                  v: {a: 0, k: 0, ix: 3},
                },
              ],
            },
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {a: 0, k: [44.785, 44.785], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  nm: '椭圆路径 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.819607843137, 0.266666666667, 1, 1],
                    ix: 3,
                  },
                  o: {a: 0, k: 40, ix: 4},
                  w: {a: 0, k: 5, ix: 5},
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: '描边 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {a: 0, k: [1, 1, 1, 1], ix: 4},
                  o: {a: 0, k: 60, ix: 5},
                  r: 1,
                  bm: 0,
                  nm: '填充 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      {
                        i: {x: 0.809, y: 1},
                        o: {x: 0.842, y: 0},
                        t: 37,
                        s: [83.018, 352.339],
                        to: [0, -121.421],
                        ti: [0, 121.421],
                      },
                      {t: 72, s: [83.018, -376.187]},
                    ],
                    ix: 2,
                  },
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [104.865, 104.865], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 37,
                        s: [0],
                      },
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 42,
                        s: [100],
                      },
                      {
                        i: {x: [0.833], y: [1]},
                        o: {x: [0.167], y: [0]},
                        t: 67,
                        s: [40],
                      },
                      {t: 72, s: [0]},
                    ],
                    ix: 7,
                  },
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: '变换',
                },
              ],
              nm: '椭圆 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: '气泡 7',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [880.018, 722.737, 0], ix: 2, l: 2},
            a: {a: 0, k: [83.018, 342.23, 0], ix: 1, l: 2},
            s: {a: 0, k: [85.095, 85.095, 100], ix: 6, l: 2},
          },
          ao: 0,
          ef: [
            {
              ty: 29,
              nm: '高斯模糊',
              np: 5,
              mn: 'ADBE Gaussian Blur 2',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: '模糊度',
                  mn: 'ADBE Gaussian Blur 2-0001',
                  ix: 1,
                  v: {a: 0, k: 18.4, ix: 1},
                },
                {
                  ty: 7,
                  nm: '模糊方向',
                  mn: 'ADBE Gaussian Blur 2-0002',
                  ix: 2,
                  v: {a: 0, k: 1, ix: 2},
                },
                {
                  ty: 7,
                  nm: '重复边缘像素',
                  mn: 'ADBE Gaussian Blur 2-0003',
                  ix: 3,
                  v: {a: 0, k: 0, ix: 3},
                },
              ],
            },
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {a: 0, k: [44.785, 44.785], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  nm: '椭圆路径 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.819607843137, 0.266666666667, 1, 1],
                    ix: 3,
                  },
                  o: {a: 0, k: 40, ix: 4},
                  w: {a: 0, k: 5, ix: 5},
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: '描边 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {a: 0, k: [1, 1, 1, 1], ix: 4},
                  o: {a: 0, k: 60, ix: 5},
                  r: 1,
                  bm: 0,
                  nm: '填充 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      {
                        i: {x: 0.809, y: 1},
                        o: {x: 0.842, y: 0},
                        t: 30,
                        s: [83.018, 352.339],
                        to: [0, -121.421],
                        ti: [0, 121.421],
                      },
                      {t: 72, s: [83.018, -376.187]},
                    ],
                    ix: 2,
                  },
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [104.865, 104.865], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 30,
                        s: [0],
                      },
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 35,
                        s: [100],
                      },
                      {
                        i: {x: [0.833], y: [1]},
                        o: {x: [0.167], y: [0]},
                        t: 67,
                        s: [40],
                      },
                      {t: 72, s: [0]},
                    ],
                    ix: 7,
                  },
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: '变换',
                },
              ],
              nm: '椭圆 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: '气泡 6',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [725.018, 917.737, 0], ix: 2, l: 2},
            a: {a: 0, k: [83.018, 342.23, 0], ix: 1, l: 2},
            s: {a: 0, k: [85.095, 85.095, 100], ix: 6, l: 2},
          },
          ao: 0,
          ef: [
            {
              ty: 29,
              nm: '高斯模糊',
              np: 5,
              mn: 'ADBE Gaussian Blur 2',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: '模糊度',
                  mn: 'ADBE Gaussian Blur 2-0001',
                  ix: 1,
                  v: {a: 0, k: 18.4, ix: 1},
                },
                {
                  ty: 7,
                  nm: '模糊方向',
                  mn: 'ADBE Gaussian Blur 2-0002',
                  ix: 2,
                  v: {a: 0, k: 1, ix: 2},
                },
                {
                  ty: 7,
                  nm: '重复边缘像素',
                  mn: 'ADBE Gaussian Blur 2-0003',
                  ix: 3,
                  v: {a: 0, k: 0, ix: 3},
                },
              ],
            },
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {a: 0, k: [44.785, 44.785], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  nm: '椭圆路径 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.819607843137, 0.266666666667, 1, 1],
                    ix: 3,
                  },
                  o: {a: 0, k: 40, ix: 4},
                  w: {a: 0, k: 5, ix: 5},
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: '描边 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {a: 0, k: [1, 1, 1, 1], ix: 4},
                  o: {a: 0, k: 60, ix: 5},
                  r: 1,
                  bm: 0,
                  nm: '填充 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      {
                        i: {x: 0.809, y: 1},
                        o: {x: 0.842, y: 0},
                        t: 26,
                        s: [83.018, 352.339],
                        to: [0, -121.421],
                        ti: [0, 121.421],
                      },
                      {t: 72, s: [83.018, -376.187]},
                    ],
                    ix: 2,
                  },
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [104.865, 104.865], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 26,
                        s: [0],
                      },
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 31,
                        s: [100],
                      },
                      {
                        i: {x: [0.833], y: [1]},
                        o: {x: [0.167], y: [0]},
                        t: 67,
                        s: [40],
                      },
                      {t: 72, s: [0]},
                    ],
                    ix: 7,
                  },
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: '变换',
                },
              ],
              nm: '椭圆 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: '气泡 5',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [471.018, 766.339, 0], ix: 2, l: 2},
            a: {a: 0, k: [83.018, 352.339, 0], ix: 1, l: 2},
            s: {a: 0, k: [65.932, 65.932, 100], ix: 6, l: 2},
          },
          ao: 0,
          ef: [
            {
              ty: 29,
              nm: '高斯模糊',
              np: 5,
              mn: 'ADBE Gaussian Blur 2',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: '模糊度',
                  mn: 'ADBE Gaussian Blur 2-0001',
                  ix: 1,
                  v: {a: 0, k: 18.4, ix: 1},
                },
                {
                  ty: 7,
                  nm: '模糊方向',
                  mn: 'ADBE Gaussian Blur 2-0002',
                  ix: 2,
                  v: {a: 0, k: 1, ix: 2},
                },
                {
                  ty: 7,
                  nm: '重复边缘像素',
                  mn: 'ADBE Gaussian Blur 2-0003',
                  ix: 3,
                  v: {a: 0, k: 0, ix: 3},
                },
              ],
            },
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {a: 0, k: [44.785, 44.785], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  nm: '椭圆路径 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.819607843137, 0.266666666667, 1, 1],
                    ix: 3,
                  },
                  o: {a: 0, k: 40, ix: 4},
                  w: {a: 0, k: 5, ix: 5},
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: '描边 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {a: 0, k: [1, 1, 1, 1], ix: 4},
                  o: {a: 0, k: 60, ix: 5},
                  r: 1,
                  bm: 0,
                  nm: '填充 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      {
                        i: {x: 0.809, y: 1},
                        o: {x: 0.842, y: 0},
                        t: 30,
                        s: [83.018, 352.339],
                        to: [0, -121.421],
                        ti: [0, 121.421],
                      },
                      {t: 72, s: [83.018, -376.187]},
                    ],
                    ix: 2,
                  },
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [104.865, 104.865], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 30,
                        s: [0],
                      },
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 35,
                        s: [100],
                      },
                      {
                        i: {x: [0.833], y: [1]},
                        o: {x: [0.167], y: [0]},
                        t: 67,
                        s: [40],
                      },
                      {t: 72, s: [0]},
                    ],
                    ix: 7,
                  },
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: '变换',
                },
              ],
              nm: '椭圆 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: '气泡 4',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [367.018, 905.339, 0], ix: 2, l: 2},
            a: {a: 0, k: [83.018, 352.339, 0], ix: 1, l: 2},
            s: {a: 0, k: [100, 100, 100], ix: 6, l: 2},
          },
          ao: 0,
          ef: [
            {
              ty: 29,
              nm: '高斯模糊',
              np: 5,
              mn: 'ADBE Gaussian Blur 2',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: '模糊度',
                  mn: 'ADBE Gaussian Blur 2-0001',
                  ix: 1,
                  v: {a: 0, k: 18.4, ix: 1},
                },
                {
                  ty: 7,
                  nm: '模糊方向',
                  mn: 'ADBE Gaussian Blur 2-0002',
                  ix: 2,
                  v: {a: 0, k: 1, ix: 2},
                },
                {
                  ty: 7,
                  nm: '重复边缘像素',
                  mn: 'ADBE Gaussian Blur 2-0003',
                  ix: 3,
                  v: {a: 0, k: 0, ix: 3},
                },
              ],
            },
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {a: 0, k: [44.785, 44.785], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  nm: '椭圆路径 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.819607843137, 0.266666666667, 1, 1],
                    ix: 3,
                  },
                  o: {a: 0, k: 40, ix: 4},
                  w: {a: 0, k: 5, ix: 5},
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: '描边 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {a: 0, k: [1, 1, 1, 1], ix: 4},
                  o: {a: 0, k: 60, ix: 5},
                  r: 1,
                  bm: 0,
                  nm: '填充 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      {
                        i: {x: 0.809, y: 1},
                        o: {x: 0.842, y: 0},
                        t: 32,
                        s: [83.018, 352.339],
                        to: [0, -121.421],
                        ti: [0, 121.421],
                      },
                      {t: 72, s: [83.018, -376.187]},
                    ],
                    ix: 2,
                  },
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [104.865, 104.865], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 32,
                        s: [0],
                      },
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 37,
                        s: [100],
                      },
                      {
                        i: {x: [0.833], y: [1]},
                        o: {x: [0.167], y: [0]},
                        t: 67,
                        s: [40],
                      },
                      {t: 72, s: [0]},
                    ],
                    ix: 7,
                  },
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: '变换',
                },
              ],
              nm: '椭圆 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: '气泡 3',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [791.018, 811.339, 0], ix: 2, l: 2},
            a: {a: 0, k: [83.018, 352.339, 0], ix: 1, l: 2},
            s: {a: 0, k: [100, 100, 100], ix: 6, l: 2},
          },
          ao: 0,
          ef: [
            {
              ty: 29,
              nm: '高斯模糊',
              np: 5,
              mn: 'ADBE Gaussian Blur 2',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: '模糊度',
                  mn: 'ADBE Gaussian Blur 2-0001',
                  ix: 1,
                  v: {a: 0, k: 18.4, ix: 1},
                },
                {
                  ty: 7,
                  nm: '模糊方向',
                  mn: 'ADBE Gaussian Blur 2-0002',
                  ix: 2,
                  v: {a: 0, k: 1, ix: 2},
                },
                {
                  ty: 7,
                  nm: '重复边缘像素',
                  mn: 'ADBE Gaussian Blur 2-0003',
                  ix: 3,
                  v: {a: 0, k: 0, ix: 3},
                },
              ],
            },
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {a: 0, k: [44.785, 44.785], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  nm: '椭圆路径 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.819607843137, 0.266666666667, 1, 1],
                    ix: 3,
                  },
                  o: {a: 0, k: 40, ix: 4},
                  w: {a: 0, k: 5, ix: 5},
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: '描边 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {a: 0, k: [1, 1, 1, 1], ix: 4},
                  o: {a: 0, k: 60, ix: 5},
                  r: 1,
                  bm: 0,
                  nm: '填充 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      {
                        i: {x: 0.809, y: 1},
                        o: {x: 0.842, y: 0},
                        t: 21,
                        s: [83.018, 352.339],
                        to: [0, -121.421],
                        ti: [0, 121.421],
                      },
                      {t: 72, s: [83.018, -376.187]},
                    ],
                    ix: 2,
                  },
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [104.865, 104.865], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 21,
                        s: [0],
                      },
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 26,
                        s: [100],
                      },
                      {
                        i: {x: [0.833], y: [1]},
                        o: {x: [0.167], y: [0]},
                        t: 67,
                        s: [40],
                      },
                      {t: 72, s: [0]},
                    ],
                    ix: 7,
                  },
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: '变换',
                },
              ],
              nm: '椭圆 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: '气泡 2',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [226.148, 779.009, 0], ix: 2, l: 2},
            a: {a: 0, k: [83.018, 342.23, 0], ix: 1, l: 2},
            s: {a: 0, k: [129.81, 129.81, 100], ix: 6, l: 2},
          },
          ao: 0,
          ef: [
            {
              ty: 29,
              nm: '高斯模糊',
              np: 5,
              mn: 'ADBE Gaussian Blur 2',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: '模糊度',
                  mn: 'ADBE Gaussian Blur 2-0001',
                  ix: 1,
                  v: {a: 0, k: 18.4, ix: 1},
                },
                {
                  ty: 7,
                  nm: '模糊方向',
                  mn: 'ADBE Gaussian Blur 2-0002',
                  ix: 2,
                  v: {a: 0, k: 1, ix: 2},
                },
                {
                  ty: 7,
                  nm: '重复边缘像素',
                  mn: 'ADBE Gaussian Blur 2-0003',
                  ix: 3,
                  v: {a: 0, k: 0, ix: 3},
                },
              ],
            },
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {a: 0, k: [44.785, 44.785], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  nm: '椭圆路径 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.819607843137, 0.266666666667, 1, 1],
                    ix: 3,
                  },
                  o: {a: 0, k: 40, ix: 4},
                  w: {a: 0, k: 5, ix: 5},
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: '描边 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {a: 0, k: [1, 1, 1, 1], ix: 4},
                  o: {a: 0, k: 60, ix: 5},
                  r: 1,
                  bm: 0,
                  nm: '填充 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      {
                        i: {x: 0.809, y: 1},
                        o: {x: 0.842, y: 0},
                        t: 28,
                        s: [83.018, 352.339],
                        to: [0, -121.421],
                        ti: [0, 121.421],
                      },
                      {t: 72, s: [83.018, -376.187]},
                    ],
                    ix: 2,
                  },
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [104.865, 104.865], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 28,
                        s: [0],
                      },
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 33,
                        s: [100],
                      },
                      {
                        i: {x: [0.833], y: [1]},
                        o: {x: [0.167], y: [0]},
                        t: 68,
                        s: [40],
                      },
                      {t: 72, s: [0]},
                    ],
                    ix: 7,
                  },
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: '变换',
                },
              ],
              nm: '椭圆 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: '气泡',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [488, 513, 0], ix: 2, l: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1, l: 2},
            s: {a: 0, k: [100, 100, 100], ix: 6, l: 2},
          },
          ao: 0,
          ef: [
            {
              ty: 29,
              nm: '高斯模糊',
              np: 5,
              mn: 'ADBE Gaussian Blur 2',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: '模糊度',
                  mn: 'ADBE Gaussian Blur 2-0001',
                  ix: 1,
                  v: {a: 0, k: 18.4, ix: 1},
                },
                {
                  ty: 7,
                  nm: '模糊方向',
                  mn: 'ADBE Gaussian Blur 2-0002',
                  ix: 2,
                  v: {a: 0, k: 1, ix: 2},
                },
                {
                  ty: 7,
                  nm: '重复边缘像素',
                  mn: 'ADBE Gaussian Blur 2-0003',
                  ix: 3,
                  v: {a: 0, k: 0, ix: 3},
                },
              ],
            },
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {a: 0, k: [44.785, 44.785], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  nm: '椭圆路径 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.819607843137, 0.266666666667, 1, 1],
                    ix: 3,
                  },
                  o: {a: 0, k: 40, ix: 4},
                  w: {a: 0, k: 5, ix: 5},
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: '描边 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {a: 0, k: [1, 1, 1, 1], ix: 4},
                  o: {a: 0, k: 60, ix: 5},
                  r: 1,
                  bm: 0,
                  nm: '填充 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      {
                        i: {x: 0.809, y: 1},
                        o: {x: 0.842, y: 0},
                        t: 16,
                        s: [83.018, 352.339],
                        to: [0, -121.421],
                        ti: [0, 121.421],
                      },
                      {t: 72, s: [83.018, -376.187]},
                    ],
                    ix: 2,
                  },
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [104.865, 104.865], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 16,
                        s: [0],
                      },
                      {
                        i: {x: [0.667], y: [1]},
                        o: {x: [0.333], y: [0]},
                        t: 21,
                        s: [100],
                      },
                      {
                        i: {x: [0.833], y: [1]},
                        o: {x: [0.167], y: [0]},
                        t: 68,
                        s: [40],
                      },
                      {t: 73, s: [0]},
                    ],
                    ix: 7,
                  },
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: '变换',
                },
              ],
              nm: '椭圆 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: '星星',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [500, 500, 0], ix: 2, l: 2},
        a: {a: 0, k: [500, 500, 0], ix: 1, l: 2},
        s: {a: 0, k: [100, 100, 100], ix: 6, l: 2},
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 120,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: '光圈',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [500, 500, 0], ix: 2, l: 2},
        a: {a: 0, k: [500, 500, 0], ix: 1, l: 2},
        s: {a: 0, k: [100, 100, 100], ix: 6, l: 2},
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 120,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: '气泡',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [500, 500, 0], ix: 2, l: 2},
        a: {a: 0, k: [500, 500, 0], ix: 1, l: 2},
        s: {a: 0, k: [100, 100, 100], ix: 6, l: 2},
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 120,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: '背景图',
      refId: 'image_4',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {
          a: 1,
          k: [
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              t: 0,
              s: [0],
            },
            {t: 72, s: [360]},
          ],
          ix: 10,
        },
        p: {a: 0, k: [500, 500, 0], ix: 2, l: 2},
        a: {a: 0, k: [500, 500, 0], ix: 1, l: 2},
        s: {a: 0, k: [100, 100, 100], ix: 6, l: 2},
      },
      ao: 0,
      ip: 0,
      op: 120,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
